<template>
  <MasterTable
    title="Roller"
    :rows="rows"
    :columns="columns"
    :loading="loading"
    :add="add"
    :update="update"
    :remove="remove"
    :reset-form-model="resetFormModel"
    :form-model="model"
    :export-data="exportData"
    remove-item-text-prop="description"
    @update-form-model="(row) => (model = row)"
  >
    <template v-slot:form="{ formMode }">
      <div class="row" style="min-width: 900px; height: 600px">
        <div class="col-5 q-pa-md">
          <div class="row">
            <div class="col-12">
              <q-select
                dense
                emit-value
                map-options
                option-value="uuid"
                option-label="description"
                :loading="loadingStateProjects.getAll"
                v-model="model.projectUuid"
                :options="projects"
                :readonly="formMode === 'update'"
                label="Projekt*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.name"
                label="Namn*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.description"
                :rules="[requiredRule]"
                label="Beskrivning*"
              />
            </div>
          </div>
        </div>

        <div class="col-7" style="border-left: 1px solid rgba(0, 0, 0, 0.12)">
          <MasterRoleTree
            v-if="model.projectUuid"
            :height="560"
            v-model="model.permissions"
            :options="permissionsFilteredByProject"
            filter-text="Filtrera behörigheter..."
            validation-text="Minst en behörighet måste väljas"
          />
          <div
            v-else
            class="text-grey-7 flex justify-center items-center q-pt-md"
          >
            <q-icon name="mdi-information-outline" class="q-mr-sm" />
            Välj ett projekt för att tillsätta behörigheter
          </div>
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useRole } from '@/composable/useRole'
import { useProject } from '@/composable/useProject'
import { usePermission } from '@/composable/usePermission'
import { Role } from '@/types/role'
import { required as requiredRule } from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
import MasterRoleTree from '@/components/master/MasterRoleTree.vue'
import { ProjectName } from '@/types/project'

export default defineComponent({
  name: 'MasterRoles',

  components: {
    MasterTable,
    MasterRoleTree,
  },

  setup() {
    const { loading: loadingStateProjects, data: projects } = useProject(true)
    const {
      loading: loadingStateRoles,
      data: roles,
      add,
      update,
      remove,
    } = useRole(true)
    const { loading: loadingStatePermissions, data: permissions } =
      usePermission(true)

    function createFormModel(): Role {
      return {
        name: '',
        description: '',
        uuid: uuidv4(),
        projectUuid: '',
        permissions: [],
      }
    }
    const model = ref<Role>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'name',
        label: 'Namn',
        align: 'left',
        field: 'name',
        sortable: true,
      },
      {
        name: 'description',
        label: 'Beskrivning',
        align: 'left',
        field: 'description',
        sortable: true,
      },
      {
        name: 'project',
        label: 'Projekt',
        align: 'left',
        field: 'project',
        sortable: true,
      },
      {
        name: 'permissions',
        label: 'Behörigheter',
        align: 'left',
        field: 'permissions',
        format: (val: []) => val.length,
        sortable: true,
      },
      {
        name: 'created',
        label: 'Skapad',
        align: 'left',
        field: 'created',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        name: 'updated',
        label: 'Uppdaterad',
        align: 'left',
        field: 'updated',
        format: (val: string) => format(new Date(val), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateRoles.value,
        getAll:
          loadingStateRoles.value.getAll ||
          loadingStateProjects.value.getAll ||
          loadingStatePermissions.value.getAll,
      }
    })

    const rows = computed(() => {
      return roles.value.map((role) => ({
        ...role,
        project: projects.value.find(
          (project) => project.uuid === role.projectUuid
        )?.description,
      }))
    })

    const exportData = computed(() => {
      return rows.value.map((role) => {
        return {
          Namn: role.name,
          Project: role.project,
          Beskrivning: role.description,
          Behörigheter: role.permissions
            .map((uuid) => {
              const permission = permissions.value.find((x) => x.uuid === uuid)
              if (permission) {
                return permission.description
              }
              return uuid
            })
            .join(', '),
        }
      })
    })

    const projectName = computed(() => {
      if (!model.value.projectUuid) return null

      const project = projects.value.find(
        (project) => project.uuid === model.value.projectUuid
      )

      if (!project) return null

      return project.name
    })

    function permissionUuidHasProject(
      permissionUuid: string,
      projectName: ProjectName
    ): boolean {
      const permission = permissions.value.find(
        (permission) => permissionUuid === permission.uuid
      )

      if (!permission || !projectName) return false

      return Boolean(permission[projectName])
    }

    // Reset permissions model on project change
    watch(
      () => model.value.projectUuid,
      () => {
        model.value.permissions = model.value.permissions.filter(
          (permissionUuid) => {
            if (!projectName.value) return false
            return permissionUuidHasProject(permissionUuid, projectName.value)
          }
        )
      }
    )

    const permissionsFilteredByProject = computed(() => {
      return permissions.value.filter((permission) => {
        if (!projectName.value) return false
        return Boolean(permission[projectName.value])
      })
    })

    return {
      permissions,
      projects,
      columns,
      model,
      requiredRule,
      rows,
      add,
      remove,
      update,
      loadingStateRoles,
      loadingStateProjects,
      loading,
      resetFormModel,
      exportData,
      permissionsFilteredByProject,
    }
  },
})
</script>
